import { html, LitElement, _$LE } from 'lit';

class MetodikyList extends LitElement {

    static properties = {
        jsonFileUrl: { type: String },
        groups: { type: Object },
    };

    constructor() {
        super();

        this.groups = {};
    }

    createRenderRoot() {
        return this;
    }

    firstUpdated() {
        this._prepareJSONData();
    }

    _prepareJSONData() {
        return new Promise((resolve, reject) => {
            if (!this.jsonFileUrl) {
                return reject("No data JSON file found!");
            }
            fetch(this.jsonFileUrl).then(res => res.json()).then(list => {
                const groups = {};
                const sortedList = list.sort((first, second) => first.id_ktp_metodika > second.id_ktp_metodika ? 1 : -1)
                sortedList.forEach(entry => {
                    if (!groups[entry.id_ktp_metodika_kategorie]) {
                        groups[entry.id_ktp_metodika_kategorie] = {
                            id_ktp_metodika_kategorie: entry.id_ktp_metodika_kategorie,
                            kategorie_nazev: entry.kategorie_nazev,
                            kategorie_poznamka: entry.kategorie_poznamka,
                            entries: [],
                        }
                        groups[entry.id_ktp_metodika_kategorie].entries.push(entry);
                    } else {
                        groups[entry.id_ktp_metodika_kategorie].entries.push(entry);
                    }
                });
                this.groups = groups;
                resolve();
            })
        });
    }

    render() {
        const groupEnum = Object.keys(this.groups);

        function unlock(textHTML){
            let parser = new DOMParser();
            let doc = parser.parseFromString(textHTML,"text/html");
            return doc.body;
        }

        return html`
            <div class="container">
                <div class="row">
                    ${groupEnum.map(groupId => {
            const group = this.groups[groupId];
            
            return html` 
                            <div class="col-12 mb-4">
                                <h2 class="mb-0" style="line-height: 1.2;">${group.kategorie_nazev}</h2>
                                <p class="mb-1" >${group.kategorie_poznamka ? unlock(group.kategorie_poznamka) : ""}</p><br>  
                                <div id="pom"></div>
                                ${group.entries.map(entry => html`
                                    <div class="my-1">
                                        ${!entry.metodika_nazev ? "" : entry.metodika_url ?
                    html`<a class="mr-3 link-arrow link-arrow-simple" href="${entry.metodika_url}">${entry.metodika_nazev}</a>` :
                    html`<span>${entry.metodika_nazev}</span>`} ${entry.metodika_poznamka}
                                    </div>
                                `)} 

                                

                            </div> 
                        `
        })}     
                </div>
            </div>
        `
    }

}

window.customElements.define('metodiky-list', MetodikyList);

//