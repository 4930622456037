import { html, LitElement } from 'lit';
const fields = ["Datum vyvěšení", "Název útvaru", "Věc", "Datum sejmutí", "Přílohy"];
const ids = ["ZverejnitOd", "NazevUtvaru", "Vec", "ZverejnitDo", "Files"]
const searchableFields = ["NazevUtvaru", "Vec", "Files"];
const perPage = 15;

class UredniDeska extends LitElement {
    static properties = {
        title: { type: String },
        location: { type: String },
        jsonFileUrl: { type: String },
        contacts: { type: Array },
        sortList: { type: Array },
        sortPrev: { type: String },
        sortCount: { type: Number },
        keywords: { type: Array },
        searchValue: { type: String },
        filteredResults: { type: Array },
        showFiltered: { type: Boolean },
        showSorted: { type: Boolean },
        page: { type: Number },
        placeholder: { type: String },
        defaultSort: { type: String }
    };
    constructor() {
        super();
        this._removeAccents = this._removeAccents.bind(this);
        this._prepareJSONData = this._prepareJSONData.bind(this);
        this._performSearch = this._performSearch.bind(this);
        this._onSearchInputChanged = this._onSearchInputChanged.bind(this);
        this._setPage = this._setPage.bind(this);
        this._onKeyPress = this._onKeyPress.bind(this);
        this._performSort = this._performSort.bind(this);
        this.contacts = [];
        this.sortedList = [];
        this.sortPrev = "ZverejnitOd";
        this.sortCount = 1;
        this.searchValue = "";
        this.showFiltered = false;
        this.showSorted = false;
        this.page = 0;
        this.defaultSort = "ZverejnitOd";
    }
    createRenderRoot() {
        return this;
    }
    firstUpdated() {
        this._prepareJSONData();
    }
    _removeAccents(str) {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }
    _prepareJSONData() {
        return new Promise((resolve, reject) => {
            if (!this.jsonFileUrl) {
                return reject("No data JSON file found!");
            }
            fetch(this.jsonFileUrl).then(res => res.json()).then(con => {
                let contacts = con;
                this.contacts = contacts;
                const keywords = []

                this.contacts.sort((a, b) => {
                    const sortA = a[this.defaultSort] ? a[this.defaultSort] : "";
                    const sortB = b[this.defaultSort] ? b[this.defaultSort] : "";
                    if (sortA < sortB) { return 1; }
                    if (sortA > sortB) { return -1; }
                    return 0;
                });

                this.contacts.map((format) => {
                    format.ZverejnitOd = format.ZverejnitOd.split(' ')[0];
                    format.ZverejnitDo = format.ZverejnitDo.split(' ')[0];
                });

                let contactsCopy = structuredClone(contacts);

                contactsCopy.forEach((contact, index) => {
                    const keyword = { index };

                    if (contact.Files) {
                        contact.Files = contact.Files.join(" ");
                    }

                    keyword.strings = searchableFields.reduce((strings, field) => {

                        if (contact[field]) {
                            strings.push(this._removeAccents(contact[field]));
                        }

                        return strings;
                    }, []);
                    keywords.push(keyword);
                });
                this.keywords = keywords;

                resolve();
            })
        });
    }
    _performSearch() {
        if (this.searchValue.length < 2) {
            return;
        }
        const simplifiedSearchValue = this._removeAccents(this.searchValue);
        const matchingIndexes = {};
        this.keywords.forEach(entry => {
            entry.strings.forEach(str => {
                const regex = new RegExp(simplifiedSearchValue, 'i');
                const match = str.match(regex);
                if (match) {
                    matchingIndexes[entry.index] = true;
                }
            });
        });
        const matchingIndexesArr = Object.keys(matchingIndexes);
        this.filteredResults = matchingIndexesArr.map(index => this.contacts[index]);
        this.showFiltered = true;
        this.showSorted = false;
        this.page = 0;
    }
    _onSearchInputChanged(e) {  // reset search filter if input is completely cleared
        this.searchValue = e.target.value
        if (e.target.value === '') {
            this.showFiltered = false;
            this.showSorted = false;
            this.page = 0;
        }
    }
    _setPage(page, pages) {
        if (page >= 0) {
            this.page = page;
        } else if (page <= pages) {
            this.page = pages;
        }
    }
    _onKeyPress(event) {  // trigger search if enter is pressed while input is focused
        if (event.keyCode === 13) {
            this._performSearch();
        }
    }
    _performSort(e)
    {
            this.sortedList = this.showFiltered ? structuredClone(this.filteredResults) : structuredClone(this.contacts);

            if(this.sortPrev != e.target.id)
                this.sortCount = (e.target.id == ids[1]) ? 1 : 0;
            else if (e.target.id == ids[1] && this.sortCount == 2)
                this.sortCount = 0;
            else if (e.target.id == ids[1] && this.sortCount == 1)
                this.sortCount = 2;
            else if (e.target.id == ids[1] && this.sortCount == 0)
                this.sortCount = 1;

            if(this.sortCount == 0){
                this.sortedList.sort((a, b) => {
                    const sortA = a[e.target.id] ? a[e.target.id] : "";
                    const sortB = b[e.target.id] ? b[e.target.id] : "";
                    if (sortA < sortB) { return 1; }
                    if (sortA > sortB) { return -1; }
                    // names must be equal
                    return 0;
                });
                this.sortCount++;
                this.showSorted = true;
            }
            else if(this.sortCount == 1){
                this.sortedList.sort((a, b) => {
                    const sortA = a[e.target.id] ? a[e.target.id] : "";
                    const sortB = b[e.target.id] ? b[e.target.id] : "";
                    if (sortA < sortB) { return -1; }
                    if (sortA > sortB) { return 1; }
                    // names must be equal
                    return 0;
                });
                this.sortCount++;
                this.showSorted = true;
            }
            else{
                this.sortCount = 0;
                this.showSorted = false;
            }

            this.sortPrev = e.target.id;
    }
    _renderTableHead() {

        const arrow = ["amount-down","amount-up-alt"]
        return html`
        <thead>
            <tr>
                ${fields.map((head,i) => html`
                    <th style="white-space: nowrap;" @click="${(i != 4 && i != 2) ? this._performSort : null}" id="${ids[i]}">
                        <span id="${ids[i]}" class="${(i != 4 && i != 2) ? '' : 'd-none'} ud-sort-icon icon icon--sort-${this.sortPrev == ids[i] ? ((this.sortCount-1) == -1 ? 'alt' : arrow[this.sortCount-1]) : 'alt'}"></span>
                        <span class="${(i != 4 && i != 2) ? 'ml-1' : 'd-none'}"></span>
                        ${head}
                    </th>
                `)}
            </tr>
        </thead>
    `
    }
    _renderTableBody(list) {
        let contactList = list;
        if (contactList.length > perPage) {
            contactList = list.slice(this.page * perPage, (this.page + 1) * perPage);
        }
        return html`
        <tbody>
            ${contactList.map(deska => html`
                <tr>
                    <td>${deska.ZverejnitOd}</td>
                    <td>${deska.NazevUtvaru}</td>
                    <td><a href="/data1/web/uredni_deska/attachments/${deska.UNID}/${deska.MainFile}" target="_blank">${deska.Vec}</a></td>
                    <td>${deska.ZverejnitDo}</td>
                    <td>
                        <ul>
                            ${!deska.Files ? null : deska.Files.map(files => html`
                                <li><a href="/data1/web/uredni_deska/attachments/${deska.UNID}/${files}" target="_blank">${files}</a></li>
                            `)}
                        </ul>
                    </td>
                </tr>
            `)}
        </tbody>
    `
    }
    _renderPagination(contactList) {
        if (contactList.length > perPage) {
            const pages = Math.ceil(contactList.length / perPage) - 1;
            const previousDisabled = this.page <= 0;
            const nextDisabled = this.page >= pages;
            return html`
            <div class="d-flex flex-row-reverse" style="margin-bottom: 30px;">
                <nav aria-label="Contacts page navigation">
                    <ul class="lfr-pagination-buttons pager">
                        <li class="page-item ${previousDisabled ? "disabled" : ""}">
                                <button
                                    class="page-link"
                                    @click=${() => this._setPage(this.page - 1, pages)}
                                    tabindex="${previousDisabled ? -1 : 0}"
                                >
                                    <span class="icon icon--arrow-left link-arrow__icon"></span>
                                </button>
                        </li>
                        <li class="page-item ${nextDisabled ? "disabled" : ""}">
                                <button
                                    class="page-link"
                                    @click=${() => this._setPage(this.page + 1, pages)}
                                    tabindex="${nextDisabled ? -1 : 0}"
                                >
                                    <span class="icon icon--arrow-right link-arrow__icon"></span>
                                </button>
                        </li>
                    </ul>
                </nav>
            </div>
        `
        }
    }
    render() {

        let contactList = [];

        if(!this.showFiltered && !this.showSorted)
            contactList = this.contacts;

        else if(this.showFiltered && !this.showSorted)
            contactList = this.filteredResults;

        else if(this.showSorted)
            contactList = this.sortedList;

        const lowRange = (this.page * perPage) + 1;
        const highRange = (this.page + 1) * perPage;
        const total = highRange > contactList.length ? contactList.length : highRange;
        return html`
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="row justify-content-center" style="margin-bottom: 75px;">
                        <div class="col-sm-12 col-xl-4 mb-2">
                            <input
                                type="search"
                                .value="${this.searchValue}"
                                class="form-control contacts-placeholder"
                                @input=${this._onSearchInputChanged}
                                @keyup=${this._onKeyPress}
                                placeholder=${this.placeholder}
                            />
                        </div>
                        <div class="col-sm-12 col-xl-2">
                            <button
                                class="btn btn-block btn-primary btn--icon ${this.searchValue.length < 2 ? "disabled" : ""}"
                                @click=${this._performSearch}
                            >
                                <span class="btn__label">Filtrovat</span>
                                <span class="btn__icon">
                                    <span class="icon icon--search"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                    <div class="d-flex flex-row justify-content-center" style="margin-bottom: 30px;">
                        <span>${`Zobrazuje se ${lowRange}-${total} z ${contactList.length} úředních oznámení`}</span>
                    </div>
                    <div class="table-responsive-wrapper">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                ${this._renderTableHead()}
                                ${this._renderTableBody(contactList)}
                            </table>
                        </div>
                    </div>
                    ${this._renderPagination(contactList)}
                </div>
            </div>
        </div>
  `}
}
window.customElements.define('uredni-deska', UredniDeska);