import { html, LitElement } from 'lit';

class Vizitky extends LitElement {

    static properties = {
        jsonFileUrl: { type: String },
        vizitky: { type: Array },
        location: { type: String },
        grant: { type: String }
    };

    constructor() {
        super();
    }

    createRenderRoot() {
        return this;
    }

    firstUpdated() {
        this._prepareJSONData()
    }

    _prepareJSONData() {
        return new Promise((resolve, reject) => {
            if (!this.jsonFileUrl) {
                return reject("No data JSON file found!");
            }
            fetch(this.jsonFileUrl).then(res => res.json()).then(vizitky => {
              this.vizitky = vizitky;
                resolve();
            })
        });
    }

    render() {
        let vizitky = this.vizitky || [];
        let location = this.location || '';
        let grant = this.grant || '';

        return html`
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="calls">
                    <div class="row">

        ${vizitky.map(function (root){return html`

            ${(root.Pracoviště == location || location == '') ? html`     
  
                ${root.Programy.map(function (programy,i){
                    return html` ${(programy['Friendly-url'] == grant || (grant == '' && i == 0)) ? html`
  
                        <div class="col-12 col-xl-4">
                            <div class="job">
                                <div class="job__content">
                                    <h2 class="job__title">${root.Jméno}</h2>
                                    
                                    <p class="job__subtitle">
                                        ${root.LabelProgramy}
                                    </p>

                                    <div class="job__info">
                                        <div class="job__info-row">
                                            <span class="job__info-label icon icon--point">${root.LabelRegionálníhoPracoviště}</span>
                                        </div>
                                        <div class="job__info-row">
                                            <span class="job__info-label icon icon--phone">${root.Telefon}</span>
                                        </div>    
                                        <div class="job__info-row">
                                            <span class="job__info-label icon icon--mail">${root['E-mail']}</span>
                                        </div>
                                    </div> 

                                </div>
                            </div>
                        </div>

                    ` : ''} 
                `})}    
            ` : ''}            
        `})}
    </div></div></div></div></div>`
    }
}

window.customElements.define('vizitky-list', Vizitky);