import {html, LitElement} from 'lit';


// allFields = ["Jméno", "Pozice", "Agenda", "Pracoviště","Telefon", "E-mail"]

const fields = ["Jméno", "Pozice", "Agenda", "Pracoviště","Telefon", "E-mail"]
const searchableFields = ["Jméno", "Pracoviště", "Pozice", "Agenda"];
const perPage = 20;

class ContactsTable extends LitElement {

  static properties = {
    title: { type: String },
    location: { type: String },
    jsonFileUrl: { type: String },
    contacts: { type: Array },
    keywords: { type: Array },
    searchValue: { type: String },
    filteredResults: { type: Array },
    showFiltered: { type: Boolean },
    page: { type: Number },
    placeholder: { type: String },
  };

  constructor() {
    super();
    this._removeAccents = this._removeAccents.bind(this);
    this._prepareJSONData = this._prepareJSONData.bind(this);
    this._performSearch = this._performSearch.bind(this);
    this._onSearchInputChanged = this._onSearchInputChanged.bind(this);
    this._setPage = this._setPage.bind(this);
    this._onKeyPress = this._onKeyPress.bind(this);


    this.contacts = [];
    this.searchValue = "";
    this.showFiltered = false;
    this.page = 0;
  }

  createRenderRoot() {
    return this;
  }

  firstUpdated() {
      this._prepareJSONData();
  }

  _removeAccents(str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  _prepareJSONData() {
      return new Promise((resolve, reject) => {
        if (!this.jsonFileUrl) {
            return reject("No data JSON file found!");
        }
        fetch(this.jsonFileUrl).then(res => res.json()).then(con => {
            let contacts = con;

            if (this.location) {
                contacts = contacts.filter(contact => contact["Pracoviště"] === this.location);
            }

            this.contacts = contacts;
            const keywords = []
            contacts.forEach((contact, index) => {
                const keyword = { index };                

                keyword.strings = searchableFields.reduce((strings, field)=> {
                    if (contact[field]) {
                        strings.push(this._removeAccents(contact[field]));
                    }
                    return strings;
                } , []);
                keywords.push(keyword);
            });

            this.keywords = keywords;

            resolve();
        })
    });
  }

  _performSearch() {
    if (this.searchValue.length < 2) {
        return;
    }

    const simplifiedSearchValue = this._removeAccents(this.searchValue);

    const matchingIndexes = {};
    this.keywords.forEach(entry => {
        entry.strings.forEach(str => {
            const regex = new RegExp(simplifiedSearchValue, 'i');
            const match = str.match(regex);
            if (match) {
                matchingIndexes[entry.index] = true;
            }
        });
    });

    const matchingIndexesArr = Object.keys(matchingIndexes);
    this.filteredResults = matchingIndexesArr.map(index => this.contacts[index]);
    this.showFiltered = true;
    this.page = 0;
  }

  _onSearchInputChanged(e) {  // reset search filter if input is completely cleared
    this.searchValue = e.target.value
    if (e.target.value === '') {
        this.showFiltered = false;
        this.page = 0;
    }
  }

  _setPage(page, pages) {
    if (page >= 0) {
      this.page = page;
    } else if (page <= pages) {
      this.page = pages;
    }
  }

  _onKeyPress(event) {  // trigger search if enter is pressed while input is focused
    if (event.keyCode === 13) {
        this._performSearch();
    }
  }

  _renderTableHead() {
    return html`
        <thead>
            <tr>
                ${fields.map(field => html`
                    <th style="white-space: nowrap;">${field}</th>
                `)}
            </tr>
        </thead>
    `
  }

  _renderTableBody(list) {
    let contactList = list;
    if (contactList.length > perPage) {
        contactList =  list.slice(this.page * perPage, (this.page + 1) * perPage);
    }

    return html`
        <tbody>
            ${contactList.map(contact => html`
                <tr>
                    ${fields.map(field =>html`
                        <th>${contact[field]}</th>
                    `)}
                </tr>
            `)}
        </tbody>
    `
  }

  _renderPagination(contactList) {
    if (contactList.length > perPage) {
        const pages = Math.ceil(contactList.length / perPage) - 1;        
        const previousDisabled = this.page <= 0;
        const nextDisabled = this.page >= pages;

        return html`
            <div class="d-flex flex-row-reverse" style="margin-bottom: 30px;">
                <nav aria-label="Contacts page navigation">
                    <ul class="lfr-pagination-buttons pager">
                        <li class="page-item ${previousDisabled ? "disabled" : ""}">
                                <button
                                    class="page-link" 
                                    @click=${() => this._setPage(this.page - 1, pages)} 
                                    tabindex="${previousDisabled ? -1: 0}"
                                >
                                    <span class="icon icon--arrow-left link-arrow__icon"></span>
                                </button>
                        </li>
                        <li class="page-item ${nextDisabled ? "disabled" : ""}">
                                <button
                                    class="page-link"
                                    @click=${() => this._setPage(this.page + 1, pages)} 
                                    tabindex="${nextDisabled ? -1: 0}"
                                >
                                    <span class="icon icon--arrow-right link-arrow__icon"></span>
                                </button>
                        </li>
                    </ul>
                </nav>
            </div>
        `
    }
  }

  render() {
    const contactList = this.showFiltered ? this.filteredResults : this.contacts;
    const lowRange = (this.page * perPage) + 1;
    const highRange = (this.page + 1) * perPage;
    const total = highRange > contactList.length ? contactList.length : highRange;

    return html`
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="row justify-content-center" style="margin-bottom: 75px;">
                        <div class="col-sm-12 col-xl-4 mb-2">
                            <input
                                type="search"
                                .value="${this.searchValue}"
                                class="form-control contacts-placeholder"
                                @input=${this._onSearchInputChanged}
                                @keyup=${this._onKeyPress}
                                placeholder=${this.placeholder}
                            />
                        </div>
                        <div class="col-sm-12 col-xl-2">
                            <button
                                class="btn btn-block btn-primary btn--icon ${this.searchValue.length < 2 ? "disabled" : ""}"
                                @click=${this._performSearch}
                            >
                                <span class="btn__label">Filtrovat</span>  
                                <span class="btn__icon">
                                    <span class="icon icon--search"></span>
                                </span>
                            </button>
                        </div>
                    </div>

                    <div class="d-flex flex-row justify-content-center" style="margin-bottom: 30px;">
                        <span>${`Zobrazuje se ${lowRange}-${total} ze ${contactList.length} kontaktů`}</span>
                    </div>
                    <div class="table-responsive-wrapper">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                ${this._renderTableHead()}
                                ${this._renderTableBody(contactList)}                       
                            </table>
                        </div>
                    </div>
                    ${this._renderPagination(contactList)}
                </div>    
            </div>
        </div>
  `}
}

window.customElements.define('contacts-table', ContactsTable);
