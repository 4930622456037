import { html, LitElement } from 'lit';

class GrantSignpost extends LitElement {

    static properties = {
        jsonFileUrl: { type: String },
        rozcestnik: { type: Array }
    };

    constructor() {
        super();
        this._onClick = this._onClick.bind(this);
    }

    createRenderRoot() {
        return this;
    }

    firstUpdated() {
       this._prepareJSONData();
    }

    _prepareJSONData() {
        return new Promise((resolve, reject) => {
            if (!this.jsonFileUrl) {
                return reject("No data JSON file found!");
            }
            fetch(this.jsonFileUrl).then(res => res.json()).then(rozcestnik => {
              this.rozcestnik = rozcestnik;
                resolve();
            })
        });
    }

    _onClick(e){

        var target = e.target;

        if(target.nodeName == "SPAN")
            target = target.parentNode;

        var level1 = target.firstChild.nextSibling;

        if(level1.classList.contains("icon--minus"))
        {
            level1.classList.remove("icon--minus"); 
            level1.classList.add("icon--plus");
            target.classList.remove("expanding-list__toggle--active")
            for(let i = 0; i < target.parentNode.children.length; i++)
            {
                if(target.parentNode.children[i].nodeName == "UL")
                    target.parentNode.children[i].classList.remove("d-block");
            }
        }
        else if (level1.classList.contains("icon--plus"))
        {
            level1.classList.remove("icon--plus"); 
            level1.classList.add("icon--minus");
            target.classList.add("expanding-list__toggle--active")
            for(let i = 0; i < target.parentNode.children.length; i++)
            {
                if(target.parentNode.children[i].nodeName == "UL")
                    target.parentNode.children[i].classList.add("d-block");
            }
        }
    }

    render() {

        let rozcestnik = this.rozcestnik || [];
        let style = "background-color:#efeeed; border-radius: 5px 5px 5px 5px; padding: 0.4em;";
        
        return html`                   
            <div class="container">
                <div class="row">
                    <div class="col-12 col-xl-8">

                    <ul class="expanding-list">
                    ${rozcestnik.map((root) => { return html`
                        <li class="expanding-list__section">
                            <button type="button" @click="${this._onClick}" class="expanding-list__toggle expanding-list__toggle--green expanding-list__toggle--green--no-border-radius">
                                <span class="icon icon--plus"></span>
                            </button>
                            <h3><span class="text-primary">${root.opatreni}</span></h3>
                       
                        <ul class="expanding-list__child">
                        
                        ${root.programy.map((programy) => { return html`

                            <li class="expanding-list__section">

                                <button type="button" @click="${this._onClick}" class="expanding-list__toggle ${programy.podprogramy ? 'expanding-list__toggle--active' : 'expanding-list__toggle--alone'} expanding-list__toggle--green">
                                    <span class="icon icon--minus"></span>
                                </button>
                                
                            ${programy.program_friendly_url ? html`
                                <a href="${programy.program_friendly_url}" class="expanding-list__section-title">
                                    ${programy.program}
                                </a>`
                            : html`
                                <span class="expanding-list__section-title expanding-list__toggle--alone">
                                    ${programy.program}
                                </span>`
                            }                   

                            <ul class="expanding-list__child expanding-list__child--category d-block">
                                ${!programy.podprogramy ? null : programy.podprogramy.map((podprogramy) => { return html`
                                <li class="expanding-list__category">

                                    <button type="button" @click="${this._onClick}" class="expanding-list__toggle  expanding-list__toggle--square ${podprogramy.vyzvy ? 'expanding-list__toggle--active' : 'expanding-list__toggle--alone'}">
                                        <span class="icon icon--minus"></span>
                                    </button>

                                    ${podprogramy.podprogram_friendly_url ? html`
                                        <a href="${podprogramy.podprogram_friendly_url}" class="expanding-list__category-title">
                                            ${podprogramy.podprogram}
                                        </a>`
                                    : html`
                                        <span class="expanding-list__category-title">
                                            ${podprogramy.podprogram}
                                        </span>`
                                    } 


                                    <ul class="expanding-list__child d-block">
                                        ${!podprogramy.vyzvy ? null : podprogramy.vyzvy.map((vyzvy) => { return html`
                                        <li class="expanding-list__leaf">
                                            ${vyzvy.vyzva_friendly_url ? html`
                                                <a href="${'-/' + vyzvy.vyzva_friendly_url}" class="expanding-list__leaf-title" style="${style}">
                                                    ${vyzvy.vyzva}
                                                </a>`
                                            : html`
                                                <span class="expanding-list__leaf-title" style="${style}">
                                                    ${vyzvy.vyzva}
                                                </span>`
                                            } 
                                        </li>`
                                        })}
                                    </ul>
                                </li>`
                                })}
                                
                                ${!programy.vyzvy ? null : programy.vyzvy.map((vyzvy) => { return html`
                                    
                                <li class="expanding-list__leaf">   
                                    ${vyzvy.vyzva_friendly_url ? html`
                                        <a href="${'-/' + vyzvy.vyzva_friendly_url}" class="expanding-list__leaf-title" style="${style}">
                                            ${vyzvy.vyzva}
                                        </a>`
                                    : html`
                                        <span class="expanding-list__leaf-title" style="${style}">
                                            ${vyzvy.vyzva}
                                        </span>`
                                    } 
                                </li>`
                                })}
                            </ul>
                            </li>`
                            })}
                        </ul>
                        </li>
                    `})}   
                    </ul>
                    </div> 
                </div>
            </div>`  
    }
}

window.customElements.define('grant-signpost', GrantSignpost);