import { html, LitElement } from 'lit';

class StandardList extends LitElement {

    static properties = {
        jsonFileUrl: { type: String },
        groups: { type: Object },
    };

    constructor() {
        super();

        this.groups = {};
    }

    createRenderRoot() {
        return this;
    }

    firstUpdated() {
        this._prepareJSONData();
    }

    _prepareJSONData() {
        return new Promise((resolve, reject) => {
            if (!this.jsonFileUrl) {
                return reject("No data JSON file found!");
            }
            fetch(this.jsonFileUrl).then(res => res.json()).then(list => {
                const groups = {};
                const sortedList = list.sort((first, second) => first.id_standard > second.id_standard ? 1 : -1)
                sortedList.forEach(entry => {
                    if (!groups[entry.id_standard_rada]) {
                        groups[entry.id_standard_rada] = {
                            id_standard_rada: entry.id_standard_rada,
                            rada: entry.rada,
                            nazev: entry.nazev,
                            poznamka: entry.poznamka,
                            entries: [],
                        }
                        groups[entry.id_standard_rada].entries.push(entry);
                    } else {
                        groups[entry.id_standard_rada].entries.push(entry);
                    }
                });
                this.groups = groups;
                resolve();
            })
        });
    }

    render() {
        const groupEnum = Object.keys(this.groups);

        return html`
            <div class="container">
                <div class="row">
                    ${groupEnum.map(groupId => {
            const group = this.groups[groupId];
            return html`
                            <div class="col-12 mb-4">
                                <h4 class="mb-0" style="line-height: 1.2;">${!group.entries[0].nazev_cz ? "Series" : "Řada"} ${group.rada} (${group.nazev})</h4>
                                <p class="mb-1">${group.poznamka}</p>
                                ${group.entries.map(entry => html`
                                    <div class="my-1">
                                        <span>${entry.cislo}</span>
                                        ${!entry.nazev_cz ? "" : entry.url_cz ?
                    html`<a href="${entry.url_cz}" target="_blank">${entry.nazev_cz}</a>` :
                    html`<span>${entry.nazev_cz}</span>`}
                                        ${!entry.nazev_en ? "" : entry.url_en ?
                    html`<a href="${entry.url_en}" target="_blank">${entry.nazev_en}</a>` :
                    html`<span>${entry.nazev_en}</span>`}
                                    </div>
                                `)}
                            </div>
                        `
        })}
                </div>
            </div>
        `
    }
}

window.customElements.define('standard-list', StandardList);